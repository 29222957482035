<div>
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="sub-section">Attachments List {{ fileList?.length > 0 ? '(' + fileList?.length + ')' : '' }}</h3>
    <button *ngIf="isAllowAttachmentChange" mat-flat-button class="upload-attachment label-uppercase" [disabled]="isUploading || isDisableUploadBtn" [appLoadingButton]="isUploading" (click)="onUploadClick()">
      {{ 'COMMON.LABEL.UPLOAD_ATTACHMENT' | translate }}
    </button>
    <input type="file" accept="image/*" #fileUpload id="fileUpload" name="fileUpload" style="display: none" multiple />
  </div>
  <!-- <div class="row no-gutters">
    <div class="col-6">
      <h3 class="sub-section">Attachments List ({{ fileList.length }})</h3>
    </div>
    <div class="col-6 upload-col">
      <button *ngIf="isAllowAttachmentChange" mat-flat-button class="upload-attachment label-uppercase" [disabled]="isUploading || isDisableUploadBtn" [appLoadingButton]="isUploading" (click)="onUploadClick()">
        {{ 'COMMON.LABEL.UPLOAD_ATTACHMENT' | translate }}
      </button>
    </div>
    <input type="file" accept="image/*" #fileUpload id="fileUpload" name="fileUpload" style="display: none" multiple />
  </div> -->
  <div class="row no-gutters my-3">
    <dx-data-grid
      #dataGrid
      appDatagridSort
      [dataSource]="fileList"
      [remoteOperations]="{ paging: true }"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [columnAutoWidth]="true"
      (onCellClick)="onDownloadFileClick($event)"
      [height]="isFixHeight ? '107px' : 'auto'"
    >
      <!-- <dxo-scrolling [useNative]="true"></dxo-scrolling> -->
      <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"></dxo-remote-operations>
      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxi-column sortOrder="desc" [width]="180" dataField="uploadedOn" caption="Upload on" cellTemplate="uploadOnCell"></dxi-column>
      <div *dxTemplate="let cell of 'uploadOnCell'">
        {{ cell.value ? (checkIsValidDate(cell.value) ? (cell.value | systemDateTimeFormat) : cell.value) : 'Pending to upload' }}
      </div>
      <dxi-column dataField="displayedFileName" caption="File Name"></dxi-column>
      <dxi-column dataField="fileSize" caption="File Size" cellTemplate="fileSizeCell"></dxi-column>
      <div *dxTemplate="let cell of 'fileSizeCell'">
        {{ cell.data.displayFileSize }}
      </div>
      <dxi-column dataField="uploadedBy" caption="Submitted By"></dxi-column>
      <dxi-column
        [cssClass]="!isAllowAttachmentChange ? 'hide-col attachment' : null"
        [width]="isAllowAttachmentChange ? 80 : 0"
        caption="Action"
        [fixed]="isAllowAttachmentChange"
        fixedPosition="right"
        [allowSorting]="false"
        cellTemplate="actionCell"
      >
        <div *dxTemplate="let cell of 'actionCell'">
          <ng-template #thenblock>
            <a class="remove-role-button-wrapper" (click)="onRemoveAdditionalAttachmentClick(cell)">
              <mat-icon>clear</mat-icon>
            </a>
          </ng-template>

          <div *ngIf="!cell.data.isUploaded; else thenblock" class="action-col">
            <!-- <a>
              <mat-icon class="more-action">more_horiz</mat-icon>
            </a> -->
            <a class="remove-role-button-wrapper" *ngIf="requestType=='REIMBURSEMENT_VOID'" (click)="onRemoveAdditionalAttachmentClick(cell)">
              <mat-icon>clear</mat-icon>
            </a>
          </div>
        </div>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
