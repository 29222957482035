import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CarPlate,
  ChangeRequestService,
  DialogData,
  DialogService,
  DropdownDictionary,
  DropdownOption,
  GenericCategory,
  GuestProfile,
  LoyaltyProgrammeTier,
  LoyaltyService,
  MemberService,
  untilDestroyed,
  UploadDetail,
  UserService,
} from '@app/@core';
import { GeneralConstants, PhoneMask } from '@shared/masks';
import { ChangeRequestConstants } from './change-request.constants';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { Observable, zip } from 'rxjs';
import { finalize, first, map } from 'rxjs/operators';
import { AttachmentRequest } from '@app/@core/models/attachment';
import { ChangeRequestDialogData, WorkflowConfig } from '@app/@core/models/workflow/request/change-request';
import { MembersConstants } from '@app/pages/members/members.constants';
import { emailValidator, phoneValidator, phoneValidatorWithParam } from '@core/validators';
import { RequestConstants } from '@app/pages/workflow/request/request.constants';
import { consolidateValidationError } from '@app/@core/utils/validators/consolidate-validation-error';
import { SyncFormUploaderComponent } from '@app/@shared/components/sync-form-uploader';
import { TranslateService } from '@ngx-translate/core';

interface DropdownOptionWithTranslatedParent extends DropdownOption {
  translatedParent?: string;
}
@Component({
  selector: 'app-change-request-dialog',
  templateUrl: './change-request-dialog.component.html',
  styleUrls: ['./change-request-dialog.component.scss'],
})
export class ChangeRequestDialogComponent implements OnInit, OnDestroy {
  @ViewChild('officeWorkerImage1', { static: false }) officeWorkerImage1: SyncFormUploaderComponent;
  @ViewChild('officeWorkerImage2', { static: false }) officeWorkerImage2: SyncFormUploaderComponent;

  guestId: string;
  memberData: GuestProfile;
  changeRequestForm: UntypedFormGroup;
  dropdownData: DropdownDictionary;
  requestTypeDropdownDataByProgram: any[];
  dropdownDataByRequestType: any[];
  requestReasonByPointType: any[];
  labels: any[] = [];
  attachments: AttachmentRequest[] = [];
  ChangeRequestConstants = ChangeRequestConstants;
  Validators = Validators;
  momentCurrentTierPointExpiryDate: moment.Moment;
  momentCurrentRedemptionPointExpiryDate: moment.Moment;
  isSubmitting = false;
  workflowConfig: WorkflowConfig[];
  tierList: LoyaltyProgrammeTier[];
  dayOfMonth: String[] = ChangeRequestConstants.DAY_OF_MONTH;
  months: any[] = ChangeRequestConstants.MONTHS;
  currentProgramExpiryLogic: string;
  terminateOfficeWorkerEmails: String[] = [];
  showOfficeLocationText = false;

  INTEGER_MASK = GeneralConstants.ADJUSTMENT_POINT;
  phoneMask = PhoneMask.DIGITS_8;
  carPlateList: CarPlate[] = [];
  public uploadSpec: UploadDetail = {
    businessUnit: this.loyaltyService.currentProgramme.businessUnit,
    loyaltyProgram: this.loyaltyService.currentProgramme.code,
    type: 'OFFICE_WORKER_IMAGE',
  };

  constructor(
    private dialogRef: MatDialogRef<ChangeRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: ChangeRequestDialogData,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    public changeRequestService: ChangeRequestService,
    private loyaltyService: LoyaltyService,
    private userService: UserService,
    private memberService: MemberService,
    private translateService: TranslateService
  ) {
    this.guestId = this.data.guestId;
    this.memberData = this.data.memberData;
    this.dropdownData = this.data.dropdownData;
    this.workflowConfig = this.data.workflowConfig;
    this.tierList = this.data.tierList;
    this.carPlateList = this.data.carPlateList;
    this.currentProgramExpiryLogic = this.loyaltyService.currentProgramme.expiryLogic;
    this.terminateOfficeWorkerEmails = this.memberData.officeWorkerEmails;

    this.consolidateRequestTypeFromConfigList(this.workflowConfig);

    this.changeRequestForm = this.fb.group({
      requestType: [null, [Validators.required]],
      requestTypeFormGroup: this.fb.group({
        reasonCode: [{ value: '', disabled: true }, [Validators.required]],
        remarks: [{ value: null, disabled: true }],
        adjustmentPoint: [{ value: null, disabled: true }, [Validators.required]],
        newExpiryDate: [{ value: null, disabled: true }, [Validators.required]],
        newTier: [{ value: null, disabled: true }, [Validators.required]],
        dayOfBirth: [{ value: null, disabled: true }],
        monthOfBirth: [{ value: null, disabled: true }],
        mobileNumberAreaCode: [{ value: null, disabled: true }],
        mobilePhone: [{ value: null, disabled: true }, phoneValidator],
        email: [{ value: null, disabled: true }, [Validators.required, emailValidator]],
        accountStatus: [{ value: null, disabled: true }, [Validators.required]],
        pointTransactionType: [{ value: null, disabled: true }, [Validators.required]],

        companyName: [{ value: null, disabled: true }],
        jobTitle: [{ value: null, disabled: true }],
        officeLocation: [{ value: null, disabled: true }],
        officeLocationText: [{ value: null, disabled: true }],
        officeWorkerCard1: [{ value: null, disabled: true }],
        officeWorkerCard2: [{ value: null, disabled: true }],
        officeWorkerCards: [{ value: [], disabled: true }],
        workEmail: [{ value: null, disabled: true }, [Validators.required, emailValidator]],
        acceptOfficeWorkerTnc: [{ value: true, disabled: true }],
        removeCarplate: [{ value: null, disabled: true }],
      }),
    });

    this.requestType.valueChanges.subscribe((value) => {
      this.showOfficeLocationText = false;
      this.updateRequestTypeFormGroup(value);

      if (value === this.ChangeRequestConstants.OFFICE_WORKER && this.memberData.officeWorkerInfo) {
        this.setupOfficeWorkerEditForm();
      }
    });

    this.pointTransactionType.valueChanges.subscribe((value) => {
      if (value) {
        this.reasonCode.enable();
        this.adjustmentPoint.enable();
      }
      if (value === ChangeRequestConstants.POINT_TYPE_TIER) {
        this.requestReasonByPointType = this.dropdownDataByRequestType.filter((option) => option.filter.includes(ChangeRequestConstants.POINT_TYPE_TIER));
      } else {
        this.requestReasonByPointType = this.dropdownDataByRequestType;
      }

      this.labelsOnChange(this.requestType.value, value);
    });

    this.momentCurrentTierPointExpiryDate = moment(this.memberData.guestTierInfoVo.tierExpiryDate).add(1, 'days');
    this.momentCurrentRedemptionPointExpiryDate = moment(this.memberData.guestTierInfoVo.redemptionPointExpiryDate).add(1, 'days');

    // user need to select area code before input phone number
    this.mobileNumberAreaCode.valueChanges.pipe(untilDestroyed(this)).subscribe((mobileAreaCode) => {
      if ((mobileAreaCode && mobileAreaCode === MembersConstants.PHONE_CODE_OTHERS) || (!mobileAreaCode && this.memberData.mobileNumberAreaCode === MembersConstants.PHONE_CODE_OTHERS)) {
        this.mobilePhone.reset();
        this.mobilePhone.disable();
        this.mobilePhone.setValidators(null);
      } else {
        this.mobilePhone.enable();
        this.mobilePhone.setValidators([phoneValidatorWithParam(this.phoneDigitByAreaCode, this.memberData.mobilePhone)]);
      }

      this.setPhoneMaskConfig();
      if (this.mobilePhone.enabled) this.mobilePhone.markAsTouched();
      this.mobilePhone.updateValueAndValidity();
    });

    this.officeLocation.valueChanges.pipe(untilDestroyed(this)).subscribe((officeLocation) => {
      if(officeLocation === 'OTHERS') {
        this.officeLocationText.enable();
        this.showOfficeLocationText = true;
      } else {
        this.officeLocationText.disable();
        this.showOfficeLocationText = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.loyaltyService.currentProgramme.mandatoryFirstOfficeWorkerCard) {
      this.changeRequestForm.get('requestTypeFormGroup').get('officeWorkerCard1').setValidators(Validators.required);
    } else {
      this.changeRequestForm.get('requestTypeFormGroup').get('officeWorkerCard1').clearValidators();
    }
  }

  ngOnDestroy() {}

  updateRequestTypeFormGroup(requestType: string) {
    this.removeAllFormControls();
    // Add different request type form
    switch (requestType) {
      case ChangeRequestConstants.MANUAL_POINT_ADJUSTMENT:
        this.remarks.enable();
        this.remarks.reset();
        this.reasonCode.reset();
        this.reasonCode.disable();
        // this.adjustmentPoint.enable();
        this.adjustmentPoint.reset();
        this.pointTransactionType.enable();
        this.pointTransactionType.reset();
        break;
      case ChangeRequestConstants.REDEMPTION_POINT_EXPIRY_DATE_EXTENSION:
        this.remarks.enable();
        this.remarks.reset();
        this.reasonCode.enable();
        this.reasonCode.reset();
        this.newExpiryDate.enable();
        this.newExpiryDate.reset();
        break;
      case ChangeRequestConstants.TIER_EXPIRY_DATE_EXTENSION:
        this.remarks.enable();
        this.remarks.reset();
        this.newExpiryDate.enable();
        this.newExpiryDate.reset();
        this.reasonCode.enable();
        this.reasonCode.reset();
        break;
      case ChangeRequestConstants.BIRTHDAY_UPDATE:
        this.remarks.enable();
        this.remarks.reset();
        this.dayOfBirth.enable();
        // this.dayOfBirth.reset();
        this.dayOfBirth.setValue(this.memberData.dayOfBirth ? this.memberData.dayOfBirth : '');
        this.monthOfBirth.enable();
        // this.monthOfBirth.reset();
        this.monthOfBirth.setValue(this.memberData.monthOfBirth);
        this.requestTypeFormGroup.setValidators([this.validBirthdayDateValidator]);
        // this.requestTypeFormGroup.setValidators([this.dayOfBirthMonofBirthValidator, this.validBirthdayDateValidator]);
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.MOBILE_UPDATE:
        this.remarks.enable();
        this.remarks.reset();
        this.mobileNumberAreaCode.enable();
        this.mobileNumberAreaCode.reset();
        this.mobilePhone.reset();
        if (this.memberData.mobileNumberAreaCode !== MembersConstants.PHONE_CODE_OTHERS) {
          this.mobilePhone.enable();
          this.mobilePhone.setValidators([phoneValidatorWithParam(this.phoneDigitByAreaCode)]);
        } else {
          this.mobilePhone.disable();
          this.mobilePhone.setValidators(null);
        }
        this.mobilePhone.updateValueAndValidity();
        this.setPhoneMaskConfig();
        this.requestTypeFormGroup.setValidators([this.mobilePhoneValidator]);
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.EMAIL_UPDATE:
        this.remarks.enable();
        this.remarks.reset();
        this.email.enable();
        this.email.reset();
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.MEMBER_STATUS_UPDATE:
        this.remarks.enable();
        this.remarks.reset();
        this.accountStatus.enable();
        this.accountStatus.reset();
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.BLACKLIST_MEMBER:
        this.remarks.enable();
        this.remarks.reset();
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.UNBLACKLIST_MEMBER:
        this.remarks.enable();
        this.remarks.reset();
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
      case ChangeRequestConstants.MANUAL_TIER_ADJUSTMENT:
        this.remarks.enable();
        this.remarks.reset();
        this.newTier.enable();
        this.newTier.reset();
        break;
      case ChangeRequestConstants.OFFICE_WORKER:
        this.companyName.enable();
        this.companyName.reset();
        this.jobTitle.enable();
        this.jobTitle.reset();
        this.officeLocation.enable();
        this.officeLocation.reset();
        this.officeWorkerCard1.enable();
        this.officeWorkerCard1.reset();
        this.officeWorkerCard2.enable();
        this.officeWorkerCard2.reset();
        this.officeWorkerCards.enable();
        this.officeWorkerCards.reset();
        this.workEmail.enable();
        this.workEmail.reset();
        this.acceptOfficeWorkerTnc.enable();
        this.acceptOfficeWorkerTnc.reset();
        this.acceptOfficeWorkerTnc.setValue(true);
        this.remarks.enable();
        this.remarks.reset();

        if (['CP', 'CG'].includes(this.loyaltyService.currentProgramme.businessUnit)) {
          this.officeLocation.setValidators(Validators.required);
        }
        break;
      case ChangeRequestConstants.OFFICE_WORKER_MANUAL_VERIFICATION:
        this.reasonCode.enable();
        this.reasonCode.reset();
        this.workEmail.enable();
        this.workEmail.reset();
        this.workEmail.setValue(this.getApprovedOWRequestEmail());
        this.remarks.enable();
        this.remarks.reset();
        break;
      case ChangeRequestConstants.OFFICE_WORKER_TERMINATION:
        this.workEmail.enable();
        this.workEmail.reset();
        if (this.terminateOfficeWorkerEmails.length === 1) {
          this.workEmail.setValue(this.terminateOfficeWorkerEmails[0]);
        }
        this.remarks.enable();
        this.remarks.reset();
        break;
      case ChangeRequestConstants.REOMVE_CAR_PLATE:
        this.removeCarplate.enable();
        this.removeCarplate.reset();
        this.removeCarplate.setValidators(Validators.required);
        this.remarks.enable();
        this.remarks.reset();
        this.requestTypeFormGroup.updateValueAndValidity();
        break;
    }
    this.labelsOnChange(requestType);
    this.consolidateRequestReasonFromConfigList(this.workflowConfig, requestType);
  }

  setupOfficeWorkerEditForm() {
    const officeWorkerInfo = cloneDeep(this.memberData.officeWorkerInfo);
    const existingCardIds: any[] = [];
    this.requestTypeFormGroup.patchValue(officeWorkerInfo);
    this.workEmail.setValue(officeWorkerInfo.email);

    officeWorkerInfo.officeWorkerCards.forEach((officeWorkerCard, index) => {
      const varNameIndex = index + 1;
      const varName = 'officeWorkerCard' + varNameIndex;
      const officeWorkerFG = this[varName] as AbstractControl;
      officeWorkerFG.setValue({
        fileName: officeWorkerInfo.officeWorkerCards[index].id,
        fileUrl: officeWorkerInfo.officeWorkerCards[index].imgPath,
        fileSize: officeWorkerInfo.officeWorkerCards[index].imgSize,
        fileType: officeWorkerInfo.officeWorkerCards[index].imgType,
      });
      existingCardIds.push(officeWorkerInfo.officeWorkerCards[index].id);
    });

    this.officeWorkerCards.setValue(existingCardIds);
  }

  removeAllFormControls() {
    Object.keys(this.requestTypeFormGroup.value).forEach((name) => {
      this.requestTypeFormGroup.get(name).setValue(null);
      if (this.requestTypeFormGroup.get(name).enabled) {
        this.requestTypeFormGroup.get(name).disable();
      }
    });
    this.requestTypeFormGroup.clearValidators();
    this.requestTypeFormGroup.updateValueAndValidity();
  }

  get requestTypeFormGroup() {
    return this.changeRequestForm.get('requestTypeFormGroup');
  }

  get requestType() {
    return this.changeRequestForm.get('requestType');
  }

  get remarks() {
    return this.requestTypeFormGroup.get('remarks');
  }

  get reasonCode() {
    return this.requestTypeFormGroup.get('reasonCode');
  }

  get adjustmentPoint() {
    return this.requestTypeFormGroup.get('adjustmentPoint');
  }

  get newExpiryDate() {
    return this.requestTypeFormGroup.get('newExpiryDate');
  }

  get dayOfBirth() {
    return this.requestTypeFormGroup.get('dayOfBirth');
  }

  get monthOfBirth() {
    return this.requestTypeFormGroup.get('monthOfBirth');
  }

  get newTier() {
    return this.requestTypeFormGroup.get('newTier');
  }

  get mobileNumberAreaCode() {
    return this.requestTypeFormGroup.get('mobileNumberAreaCode');
  }

  get mobilePhone() {
    return this.requestTypeFormGroup.get('mobilePhone');
  }

  get email() {
    return this.requestTypeFormGroup.get('email');
  }

  get accountStatus() {
    return this.requestTypeFormGroup.get('accountStatus');
  }

  get pointTransactionType() {
    return this.requestTypeFormGroup.get('pointTransactionType');
  }

  get companyName() {
    return this.requestTypeFormGroup.get('companyName');
  }

  get jobTitle() {
    return this.requestTypeFormGroup.get('jobTitle');
  }

  get officeLocation() {
    return this.requestTypeFormGroup.get('officeLocation');
  }

  get officeLocationText() {
    return this.requestTypeFormGroup.get('officeLocationText');
  }

  get officeWorkerCardIds() {
    return this.requestTypeFormGroup.get('officeWorkerCardIds');
  }

  get officeWorkerCards() {
    return this.requestTypeFormGroup.get('officeWorkerCards');
  }

  get workEmail() {
    return this.requestTypeFormGroup.get('workEmail');
  }

  get officeWorkerCard1() {
    return this.requestTypeFormGroup.get('officeWorkerCard1');
  }

  get officeWorkerCard2() {
    return this.requestTypeFormGroup.get('officeWorkerCard2');
  }

  get acceptOfficeWorkerTnc() {
    return this.requestTypeFormGroup.get('acceptOfficeWorkerTnc');
  }

  // copy from create-member
  get isPhoneDisabled() {
    return this.mobileNumberAreaCode.value && this.mobileNumberAreaCode.value === 'Others';
  }

  get phoneDigitByAreaCode() {
    const mobileNumberAreaCode = this.mobileNumberAreaCode.value || this.memberData.mobileNumberAreaCode;
    switch (mobileNumberAreaCode) {
      case MembersConstants.PHONE_CODE_852:
      case MembersConstants.PHONE_CODE_853:
        {
          return 8;
        }
      case MembersConstants.PHONE_CODE_OTHERS:
        // should never reach this line
        {
          return null;
        }
      case MembersConstants.PHONE_CODE_86: {
        {
          return 11;
        }
      }
      case MembersConstants.PHONE_CODE_886: {
        {
          return 9;
        }
      }
      default: {
        return null;
      }
    }
  }
  get removeCarplate() {
    return this.requestTypeFormGroup.get('removeCarplate');
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSubmitChangeClick() {
    const dialogData: DialogData = {
      content: 'REQUEST.MESSAGE.CONFIRM',
      yesLabel: 'COMMON.LABEL.CONFIRM',
      yesCallback: (dialogRef) => {
        this.saveFile();
        dialogRef.close();
      },
      noLabel: 'COMMON.LABEL.CANCEL',
      noCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showConfirmationDialog(dialogData).subscribe();
  }

  labelsOnChange(requestType: string, pointType?: string) {
    switch (requestType) {
      case ChangeRequestConstants.MANUAL_POINT_ADJUSTMENT:
        if (pointType === RequestConstants.POINT_ADJUSTMENT_REQUEST_POINT_TYPE_REDEMPTION) {
          this.labels = [
            {
              label: 'MEMBER.LABEL.AVAILABLE_REDEMPTION_POINTS',
              field: 'availableRedemptionPoints',
            },
            {
              label: 'MEMBER.LABEL.REDEMPTION POINT EXPIRY DATE',
              field: 'redemptionPointExpiryDate',
            },
          ];
        } else if (pointType === RequestConstants.POINT_ADJUSTMENT_REQUEST_POINT_TYPE_TIER) {
          this.labels = [
            {
              label: 'MEMBER.LABEL.MEMBERSHIP_TIER_POINTS',
              field: 'memberTierPoints',
            },
            {
              label: 'MEMBER.LABEL.TIER_EXPIRY_DATE',
              field: 'tierExpiryDate',
            },
          ];
        } else this.labels = [];
        break;
      case ChangeRequestConstants.REDEMPTION_POINT_EXPIRY_DATE_EXTENSION:
        this.labels = [
          {
            label: 'MEMBER.LABEL.AVAILABLE_REDEMPTION_POINTS',
            field: 'availableRedemptionPoints',
          },
          {
            label: 'MEMBER.LABEL.REDEMPTION POINT EXPIRY DATE',
            field: 'redemptionPointExpiryDate',
          },
        ];
        break;
      case ChangeRequestConstants.TIER_EXPIRY_DATE_EXTENSION:
      case ChangeRequestConstants.MANUAL_TIER_ADJUSTMENT:
        this.labels = [
          {
            label: 'MEMBER.LABEL.MEMBERSHIP_TIER_POINTS',
            field: 'memberTierPoints',
          },
          {
            label: 'MEMBER.LABEL.TIER_EXPIRY_DATE',
            field: 'tierExpiryDate',
          },
        ];
        break;
      default:
        this.labels = [];
    }
  }

  // the phone mask alter according to the area code selected
  setPhoneMaskConfig() {
    const mobileNumberAreaCode = this.mobileNumberAreaCode.value || this.memberData.mobileNumberAreaCode;
    switch (mobileNumberAreaCode) {
      case MembersConstants.PHONE_CODE_852:
      case MembersConstants.PHONE_CODE_853:
        {
          this.phoneMask = PhoneMask.DIGITS_8;
        }
        break;
      case MembersConstants.PHONE_CODE_OTHERS:
        {
          this.phoneMask = null;
        }
        break;
      case MembersConstants.PHONE_CODE_86: {
        {
          this.phoneMask = PhoneMask.DIGITS_11;
        }
        break;
      }
      case MembersConstants.PHONE_CODE_886: {
        {
          this.phoneMask = PhoneMask.DIGITS_9;
        }
        break;
      }
    }
  }

  attachmentOnChange(e: any[]) {
    this.attachments = cloneDeep(e);
  }

  // monthlyMatDatepickerFilter(date: moment.Moment) {
  //   if (date) {
  //     const currentDate = date.toDate();
  //     const endDate = moment(date).endOf('month').toDate();
  //     return currentDate.getMonth() === endDate.getMonth() && currentDate.getDate() === endDate.getDate();
  //   } else return false;
  // }

  // quarterlyDatePickerFilter(date: moment.Moment) {
  //   if (date) {
  //     const currentDate = date.toDate();
  //     const endDate = moment(date).endOf('month').toDate();
  //     return [2, 5, 8, 11].includes(currentDate.getMonth()) && currentDate.getMonth() === endDate.getMonth() && currentDate.getDate() === endDate.getDate();
  //   } else return false;
  // }

  filterRequestReasonByTier() {
    const tier = this.memberData.guestTierInfoVo.currentTier;
    if (ChangeRequestConstants.BLACK_TIER.includes(tier)) {
      this.dropdownDataByRequestType = this.dropdownDataByRequestType.filter((option) => option.value === ChangeRequestConstants.TIER_EXPIRY_DATE_EXTENSION_BL);
    } else if (ChangeRequestConstants.NON_BLACK_TIER.includes(tier)) {
      this.dropdownDataByRequestType = this.dropdownDataByRequestType.filter((option) => option.value === ChangeRequestConstants.TIER_EXPIRY_DATE_EXTENSION_NON_BL);
    }
  }

  async submitRecord(filePathArr?: string[]) {
    const requestBody = cloneDeep(this.requestTypeFormGroup.value);
    // if (this.requestType.value === ChangeRequestConstants.MANUAL_POINT_ADJUSTMENT) requestBody.pointTransactionType = 'REDEMPTION';

    if (this.requestType.value === ChangeRequestConstants.OFFICE_WORKER) {
      let currentCards: string[] = this.officeWorkerCards.value;
      let officeWorkerCard1Uuid = currentCards?.[0];
      let officeWorkerCard2Uuid = currentCards?.[1];

      if (this.officeWorkerImage1.files.new) {
        const fileNameArray = this.officeWorkerImage1.files.new.fileName.split('.');
        const params = {
          imgSize: this.officeWorkerImage1.files.new.file.size,
          imgType: fileNameArray.splice(fileNameArray.length - 1, 1).toString(),
        };
        officeWorkerCard1Uuid = await this.memberService.uploadOfficeWorkerCard(this.officeWorkerImage1.files.new.file, params).toPromise();
      }
      if (this.officeWorkerImage2.files.new) {
        const fileNameArray = this.officeWorkerImage2.files.new.fileName.split('.');
        const params = {
          imgSize: this.officeWorkerImage2.files.new.file.size,
          imgType: fileNameArray.splice(fileNameArray.length - 1, 1).toString(),
        };
        officeWorkerCard2Uuid = await this.memberService.uploadOfficeWorkerCard(this.officeWorkerImage2.files.new.file, params).toPromise();
      }
      requestBody.officeWorkerCardIds = [officeWorkerCard1Uuid];
      if (officeWorkerCard2Uuid) requestBody.officeWorkerCardIds.push(officeWorkerCard2Uuid);

      delete requestBody.officeWorkerCard1;
      delete requestBody.officeWorkerCard2;
      delete requestBody.officeWorkerCards;
    }

    requestBody.attachments = cloneDeep(this.attachments);
    if (filePathArr)
      requestBody.attachments = requestBody.attachments.map((attachment: any, index: number) => {
        attachment.filePath = filePathArr[index];
        delete attachment.file;
        return attachment;
      });

    requestBody.guestId = this.memberData.id;
    if (ChangeRequestConstants.MEMBER_PROFILE_REQUEST.includes(this.requestType.value)) {
      this.changeRequestService
        .memberUpdateChangeRequest(this.requestType.value, requestBody)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
          }),
          untilDestroyed(this)
        )
        .subscribe((res: any) => {
          this.dialogRef.close();
          this.onSuccessRedirect();
        });
    } else {
      this.changeRequestService
        .changeRequest(this.requestType.value, requestBody)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
          }),
          untilDestroyed(this)
        )
        .subscribe((res: any) => {
          this.dialogRef.close();
          this.onSuccessRedirect();
        });
    }
  }

  onSuccessRedirect() {
    const dialogData: DialogData = {
      content: 'REQUEST.MESSAGE.SUCCESS',
      buttonOneLabel: 'REQUEST.LABEL.RETURN_MEMBER_DETAIL_PAGE',
      width: '370px',
      buttonOneCallback: (dialogRef) => {
        dialogRef.close();
      },
    };

    this.dialogService.showSuccessDialog(dialogData).subscribe();
  }

  saveFile() {
    this.isSubmitting = true;
    if (this.attachments.length > 0) {
      const fileHandlingObservable: Observable<any>[] = [];
      this.attachments.forEach((attachment) => {
        fileHandlingObservable.push(this.changeRequestService.uploadAttachment(attachment.file));
      });

      zip(...fileHandlingObservable)
        .pipe(untilDestroyed(this))
        .subscribe((res: any[]) => {
          this.submitRecord(res);
        });
    } else {
      this.submitRecord();
    }
  }

  consolidateRequestTypeFromConfigList(configList: WorkflowConfig[]) {
    const requestType = [...new Set(configList.map((config) => config.type))];

    let requestTypeList: DropdownOptionWithTranslatedParent[] = cloneDeep(this.dropdownData?.WORKFLOW.REQUEST_TYPE);

    if (!this.userService.hasPermission('loyalty#guest#workflow#workflowRequest#blacklistMember#submit')) {
      requestTypeList = requestTypeList.filter((option) => option.value !== ChangeRequestConstants.BLACKLIST_MEMBER);
    }
    if (!this.userService.hasPermission('loyalty#guest#workflow#workflowRequest#unblacklistMember#submit')) {
      requestTypeList = requestTypeList.filter((option) => option.value !== ChangeRequestConstants.UNBLACKLIST_MEMBER);
    }
    if (!this.userService.hasPermission('ui#loyalty#guest#contactlessCarparking#removeCarplate')) {
      requestTypeList = requestTypeList.filter((option) => option.value !== ChangeRequestConstants.REOMVE_CAR_PLATE);
    }
    if (!this.userService.hasPermission('loyalty#guest#contactlessCarparking#removeCarplate')) {
      requestTypeList = requestTypeList.filter((option) => option.value !== ChangeRequestConstants.REOMVE_CAR_PLATE);
    }
    
    requestTypeList = requestTypeList.filter((option) => option.value != ChangeRequestConstants.REIMBURSEMENT_VOID)

    this.requestTypeDropdownDataByProgram = requestTypeList
      .filter((option) => requestType.includes(option.value))
      .map((option) => {
        option.translatedParent = this.translateService.instant(`REQUEST.LABEL.${option.parent}`);
        return option;
      });
    console.log(this.requestTypeDropdownDataByProgram);
    if (!this.memberData.guestTierInfoVo.availableRedemptionPoints && !this.memberData.guestTierInfoVo.redemptionPointExpiryDate)
      this.requestTypeDropdownDataByProgram = this.requestTypeDropdownDataByProgram.filter((option) => option.value !== ChangeRequestConstants.REDEMPTION_POINT_EXPIRY_DATE_EXTENSION);
    if (!this.memberData.guestTierInfoVo.redemptionPointExpiryDate && !this.memberData.guestTierInfoVo.availableRedemptionPoints)
      this.requestTypeDropdownDataByProgram = this.requestTypeDropdownDataByProgram.filter((option) => option.value !== ChangeRequestConstants.MANUAL_POINT_ADJUSTMENT);
    this.requestTypeDropdownDataByProgram = this.requestTypeDropdownDataByProgram.filter((type) => type.filter === ChangeRequestConstants.MEMBER_REQUEST_TYPE);
    if (this.memberData.accountStatus === ChangeRequestConstants.NON_ACTIVATED) {
      this.requestTypeDropdownDataByProgram = this.requestTypeDropdownDataByProgram.filter((type) => ChangeRequestConstants.NON_ACTIVATED_MEMBER_REQUEST_TYPE.includes(type.value));
    }

    if (!this.getApprovedOWRequestEmail()) {
      this.requestTypeDropdownDataByProgram = this.requestTypeDropdownDataByProgram.filter((type) => type.value !== ChangeRequestConstants.OFFICE_WORKER_MANUAL_VERIFICATION);
    }
  }

  getApprovedOWRequestEmail() {
    if (!this.memberData.officeWorkerInfo) return null;
    if (this.memberData.officeWorkerInfo.status === 'APPROVED') return this.memberData.officeWorkerInfo.email;

    const owEmailList = this.memberData.officeWorkerEmails;
    const newApprovedEmails = owEmailList.filter((email) => email !== this.memberData.officeWorkerInfo.email);
    return newApprovedEmails[0] || null;
  }

  consolidateRequestReasonFromConfigList(configList: WorkflowConfig[], requestType: string) {
    const configListFilteredByRequestType = configList.filter((config) => config.type === requestType).map((config) => config.reason);
    this.dropdownDataByRequestType = this.dropdownData?.WORKFLOW.REQUEST_REASON.filter((option) => option.parent === requestType).filter((option) => configListFilteredByRequestType.includes(option.value));
  }

  convertMonthToLabel(day: string, month: string) {
    return moment()
      .month(Number(month) - 1)
      .format('MMMM');
  }

  validBirthdayDateValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const dayOfBirth = fg.get('dayOfBirth').value;
    const monthOfBirth = fg.get('monthOfBirth').value;
    const checkDayOfBirth = (dayOfBirth: any) => {
      return dayOfBirth === this.memberData.dayOfBirth || dayOfBirth === '' || dayOfBirth === null || dayOfBirth === undefined;
    };

    if (monthOfBirth !== this.memberData.monthOfBirth && checkDayOfBirth(dayOfBirth)) {
      this.dayOfBirth.setErrors(null);
      return null;
    }

    const isValidDay = moment(`${dayOfBirth || this.memberData.dayOfBirth}-${monthOfBirth}-2020`, 'DD-MM-YYYY').isValid();
    if (isValidDay) {
      this.dayOfBirth.setErrors(null);
      return null;
    } else {
      this.dayOfBirth.setErrors({ invalidDate: true });
      return { dayMonthInvalid: true };
    }
  };

  mobilePhoneValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const mobileNumberAreaCode = fg.get('mobileNumberAreaCode').value;
    const mobileNumber = fg.get('mobilePhone').value;
    return (!mobileNumberAreaCode && !mobileNumber) ||
      (this.memberData.mobileNumberAreaCode === MembersConstants.PHONE_CODE_OTHERS &&
        this.memberData.mobilePhone &&
        String(mobileNumberAreaCode) === this.memberData.mobileNumberAreaCode &&
        String(mobileNumber) === this.memberData.mobilePhone) ||
      (this.memberData.mobileNumberAreaCode === MembersConstants.PHONE_CODE_OTHERS &&
        !this.memberData.mobilePhone &&
        mobileNumberAreaCode !== MembersConstants.PHONE_CODE_OTHERS &&
        mobileNumberAreaCode &&
        !mobileNumber) ||
      (this.memberData.mobileNumberAreaCode !== MembersConstants.PHONE_CODE_OTHERS &&
        ((mobileNumberAreaCode === this.memberData.mobileNumberAreaCode && !mobileNumber) || (mobileNumber === this.memberData.mobilePhone && !mobileNumberAreaCode))) ||
      (this.memberData.mobilePhone
        ? String(mobileNumberAreaCode) === this.memberData.mobileNumberAreaCode && String(mobileNumber) === this.memberData.mobilePhone
        : String(mobileNumberAreaCode) === this.memberData.mobileNumberAreaCode)
      ? { invalidAreaCodeMobilePhone: true }
      : null;
  };
}
