import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ReimbursementConstants } from '@app/pages/reimbursement/reimbursement.constants';

@Directive({
  selector: '[reimbursementStatus]',
})
export class ReimbursementStatusDirective implements OnInit {
  @Input('reimbursementStatus') status: string;
  @Input('prefix') prefix: string = '';
  private domElement: HTMLElement;
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    const requiredClasses: string[] = ['dt-status-badge'];
    let prefixStr = this.prefix;
    if (prefixStr != '') prefixStr += ': ';
    let innerText = '';
    switch (this.status) {
      case ReimbursementConstants.STATUS_PENDING:
        requiredClasses.push('gold');
        innerText = 'Pending';
        break;
      case ReimbursementConstants.STATUS_SUBMITTED:
        requiredClasses.push('green');
        innerText = 'Submitted';
        break;
      case ReimbursementConstants.STATUS_APPROVED:
        requiredClasses.push('green');
        innerText = 'Approved';
        break;
      case ReimbursementConstants.STATUS_REJECTED:
        requiredClasses.push('red');
        innerText = 'Rejected';
        break;
      case ReimbursementConstants.STATUS_EXPIRED:
        requiredClasses.push('grey');
        innerText = 'Expired';
        break;
      case ReimbursementConstants.STATUS_REVERTED:
        requiredClasses.push('yellow');
        innerText = 'Reverted';
        break;

      case ReimbursementConstants.STATUS_ENDORSED:
        requiredClasses.push('blue');
        innerText = 'Endorsed';
        break;
      case ReimbursementConstants.STATUS_VOIDED:
        requiredClasses.push('red');
        innerText = 'Voided';
        break;
      case ReimbursementConstants.STATUS_PFE:
        requiredClasses.push('gold');
        innerText = 'PFE';
        break;
      default:
        requiredClasses.push('gold');
        innerText = this.status;
    }
    this.domElement.innerText = prefixStr + innerText;
    requiredClasses.forEach((newClass) => {
      this.renderer.addClass(this.domElement, `${newClass}`);
    });
  }
}
