<ng-container autoFocus>
  <div class="dialog-container">
    <h3 class="dialog-title">{{'REIMBURSEMENT.LABEL.SELECT_VOID' | translate}} </h3>
    <form [formGroup]="revertForm">
      <mat-form-field class="full-width">
        <mat-label style="color: #1b4266;">Select Void Reason(s)</mat-label>
        <mat-select formControlName="reasons" multiple>
          <mat-option *ngFor="let item of voidData" [value]="item.value">{{ item.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="revertForm.get('reasons').hasError('required')">{{'COMMON.LABEL.REQUIRED_FIELD' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label style="color: #1b4266;">Remarks</mat-label>
        <input matInput autocomplete="off" formControlName="remark" maxlength="500" placeholder="" />
        <mat-error *ngIf="revertForm.get('remark').hasError('required')">{{'COMMON.LABEL.REQUIRED_FIELD' | translate}}</mat-error>
      </mat-form-field>
      <div class="form-stick-bottom-bar revert-bottom-bar">
        <c-form-button buttonType="stroke" [isLoading]="isLoading" label="cancel" class="grey" [upperCaseLabel]="true" (click)="onCancelClick()"> </c-form-button>
        <c-form-button label="COMMON.LABEL.CONFIRM" [isLoading]="isLoading" [upperCaseLabel]="true" (click)="onClickSubmit()"></c-form-button>
      </div>
    </form>
  </div>
</ng-container>
