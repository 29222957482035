import { Directive, ElementRef, OnChanges, OnInit, AfterViewInit } from '@angular/core';

@Directive({
  selector: '.form-stick-bottom-bar',
})
export class AutoHideFooterDirective implements OnInit, OnChanges, AfterViewInit {
  private nativeEle: HTMLDivElement;

  constructor(private el: ElementRef) {
    this.nativeEle = el.nativeElement;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    let ifVisibleButtonExist = false;
    const footerButtonList: NodeListOf<HTMLButtonElement | HTMLAnchorElement> = this.nativeEle.querySelectorAll('a, button');

    footerButtonList.forEach((button) => {
      if (button.parentElement.tagName.toUpperCase() === 'C-FORM-BUTTON') {
        if (button.parentElement.style.display !== 'none') ifVisibleButtonExist = true;
      } else {
        if (button.style.display !== 'none') ifVisibleButtonExist = true;
      }
    });

    if (!ifVisibleButtonExist) {
      this.nativeEle.style.display = 'none';
    }
  }

  ngOnChanges() {}
}
