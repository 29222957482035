import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DialogData, DialogService, ImageService, LoyaltyService, ReceiptImageService, ReceiptTransactionService, ReimburseImage, untilDestroyed } from '@app/@core';
import { ProcessedReceiptImage } from '../../image-uploader';
import { generateUuid } from '@app/@core/utils';
import { cloneDeep } from 'lodash';
import { repeat, timer, timeout } from 'rxjs';
import ImageOcrStatus from '../../image-uploader/model/image-ocr-status';
import ImageProcessingStatus from '../../image-uploader/model/image-processing-status';
import { ReimbursementService } from '@app/@core/services/reimbursement/reimbursement.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'reimbursement-image-upload',
  templateUrl: './reimbursement-image-upload.component.html',
  styleUrls: ['./reimbursement-image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReimbursementImageUploadComponent implements OnInit {
  @Input() maxFile: number;
  @Input() fileCategoryLabel: string; // Label for the uploader
  @Input() fileCategoryName: string; // Label for the uploader
  @Input() fileSizeLimit: number;
  @Output() onDeleteImage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeImage: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileInputRef') fileInputRef: ElementRef;
  fileList: File[] = [];
  receiptImages: ReimburseImage[] = [];
  maxFileCount: number;
  isSubmitting: boolean;
  isEnableAlbumPicker: boolean;

  constructor(
    private dialogService: DialogService,
    private imageService: ImageService,
    private receiptImageService: ReceiptImageService,
    private receiptTransactionService: ReceiptTransactionService,
    private reimbursementService: ReimbursementService,
    private cd: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private loyaltyService: LoyaltyService
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileList) {
      this.cd.detectChanges();
      this.cd.markForCheck();
    }
  }
  uploaderWrapperDragOver(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.add('dragging');
  }

  uploaderWrapperDragLeave(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
  }

  uploaderWrapperDragEnd(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
  }

  uploaderWrapperDrop(event: any, uploaderWrapper: any): void {
    event.preventDefault();
    uploaderWrapper.classList.remove('dragging');
    if (event.dataTransfer.files.length > this.maxFileCount) {
      return;
    }
    event.target.files = event.dataTransfer.files;
    this.onSelectFile(event);
  }
  /**
   * Select File Event
   * Temp Disable Multi Select Files
   * @parm event
   */
  onSelectFile(event: Event): void {
    Array.from((event.target as HTMLInputElement).files).forEach((file) => {
      this.addImageToFileQueue(file);
    });
    // Clear uploader for next file
    this.fileInputRef.nativeElement.value = '';
    this.onChangeImage.emit(this.receiptImages);
  }

  onClickUploaderWrapper(event: any): void {
    if (event.target.type !== 'file') {
      if (event.target.innerHTML === 'collections') {
        this.isEnableAlbumPicker = true;
        setTimeout(() => {
          this.fileInputRef.nativeElement.click();
          this.isEnableAlbumPicker = false;
        }, 100);
      } else {
        this.fileInputRef.nativeElement.click();
      }
    }
  }
  private addImageToFileQueue(file: File): void {
    // Compress Image

    this.imageService.compressImage(file).then(
      (compressedImage) => {
        this.fileList.push(compressedImage);
        this.uploadReceiptImage(compressedImage);
      },
      (error: Error) => {
        // Error in compress image
        if (error.message === 'Oversize') {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The ${this.fileCategoryLabel} file size is greater than 5MB`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        } else if (error.message === 'dataURLtoImage(): dataURL is illegal') {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The file format is not accepted.`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        } else {
          const dialogData: DialogData = {
            title: 'Error',
            content: `The ${this.fileCategoryLabel} file hit unknown error.`,
            yesCallback: (dialogRef) => {
              dialogRef.close();
            },
          };
          this.dialogService.showErrorDialog(dialogData).subscribe();
        }
      }
    );
  }
  private uploadReceiptImage(file: File): void {
    let repeatTime = 1;
    this.reimbursementService
      .uploadReimbursementImage({ sourceFile: file, bu: this.loyaltyService.currentProgramme.businessUnit, lp: this.loyaltyService.currentProgramme.code })
      .pipe(untilDestroyed(this), repeat(repeatTime))
      .subscribe({
        next: (res: ReimburseImage) => {
          // res.imgPath = res.imgPath;
          // res.thumbnailPath = res.thumbnailPath;
          this.reimbursementService.setImageUploadedValue(res);
        },
        error: () => {
          const fileList = cloneDeep(this.fileList);
          const index = this.fileList.lastIndexOf(file);
          fileList.splice(index, 1);
          const deletedImg = this.receiptImages.splice(index, 1);
          this.onDeleteImage.emit(deletedImg);
          this.onChangeImage.emit(true);
          this.cd.markForCheck();
        },
      });
  }
}
