<ng-container autoFocus>
  <div class="dialog-container">
    <div class="close-button">
      <button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <h3 class="dialog-title">Reimbursement Summary History</h3>

    <dx-data-grid #dataGrid appDatagridSort [dataSource]="dataSource" [hoverStateEnabled]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
      <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"> </dxo-remote-operations>
      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxo-search-panel [visible]="false"></dxo-search-panel>
      <dxi-column dataField="remark" [allowSorting]="false" caption="Action"></dxi-column>
      <dxi-column dataField="date" [allowSorting]="false" caption="Update At" cellTemplate="dateTimeTemplate"></dxi-column>
      <div *dxTemplate="let data of 'dateTimeTemplate'">
        {{ data.value | systemDateTimeFormat }}
      </div>
      <dxi-column dataField="user" [allowSorting]="false" caption="Update By"></dxi-column>
    </dx-data-grid>
  </div>
</ng-container>
