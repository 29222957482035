<carousel class="carouselContainer" [height]="80" [cellWidth]="80" [margin]="10" [ngClass]="{ isBottomThumbnail: isBottomThumbnail, isNotBottomThumbnail: !isBottomThumbnail }" [cellCount]="transformedImages.length">
  <div *ngFor="let image of transformedImages" class="carousel-cell thumbnail-card" [class.is-active-image]="image?.id === selectedImage?.id">
    <span class="delete-button" *ngIf="canDelete" (click)="onClickDeleteImage(image)">
      <mat-icon>delete</mat-icon>
    </span>
    <div (click)="onSelectThumbnail(image)">
      <img class="card-img" [src]="image.thumbnailUrl" alt="thumbnail image" />
    </div>
  </div>
</carousel>
