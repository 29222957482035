import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReceiptTransactionService, ReceiptImageService, ImageListItem, ReimburseImage, DialogData, DialogService } from '@app/@core';
import { id } from 'date-fns/locale';

@Component({
  selector: 'reimbursement-image-list',
  templateUrl: './reimbursement-image-list.component.html',
  styleUrls: ['./reimbursement-image-list.component.scss'],
})
export class ReimbursementImageListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() images: ReimburseImage[];
  @Input() isBottomThumbnail = false;
  @Input() imageViewerId: string;
  @Input() canDelete: boolean = true;
  @Output() selectImageEvent: EventEmitter<any> = new EventEmitter();
  @Output() clickDelete = new EventEmitter<any>();
  transformedImages: ImageListItem[] = [];
  selectedImage: ImageListItem;

  constructor(private dialogService: DialogService, private receiptTransactionService: ReceiptTransactionService, private domSanitizer: DomSanitizer, private viewTransactionImageService: ReceiptImageService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.viewTransactionImageService.selectedImage.subscribe((selectedImage) => {
      if (selectedImage && this.imageViewerId === selectedImage.imageViewerId) {
        this.selectedImage = selectedImage.imageSrc;
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('image-list on Change:');
    console.log(changes);
    if (changes.images) {
      this.transformImagesUrl();
    }
  }

  async transformImagesUrl() {
    this.transformedImages = [];
    console.log(this.images);
    for await (const image of this.images) {
      this.transformedImages.push({
        thumbnailUrl: image.thumbnailPath,
        originalUrl: image.imgPath,
        caption: '',
        id: image.uuid,
      });
    }
    console.log('transformedImages:', this.transformedImages);
  }

  getThumbnailLink(id: string) {
    return this.receiptTransactionService.getReceiptThumbnail(id, 'thumbnail');
  }

  onSelectThumbnail(item: ImageListItem) {
    this.viewTransactionImageService.setSelectedViewerImage(item, this.imageViewerId);
  }
  onClickDeleteImage(item: ImageListItem) {
    const dialogData: DialogData = {
      content: `Are you sure want to delete this photo?`,
      id: 'confirm-delete-photo-dialog',
      title: 'Delete',
      isErrorDialogStyle: true,
      yesLabel: 'Confirm',
      noLabel: 'Cancel',
      yesCallback: (dialogRef) => {
        this.clickDelete.emit(item);
        dialogRef.close();
      },
      noCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showConfirmationDialog(dialogData).subscribe();
  }
}
