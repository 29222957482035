import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../auth';
import { LoyaltyService } from '../loyalty';

@Injectable()
export class SmartLockerSystemResolver {
  constructor(private router: Router, private userService: UserService, private loyaltyService: LoyaltyService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.loyaltyService.currentProgramme?.businessUnit == 'CG') {
      const getSmartLockerId = await this.userService.systemConfig({
        key: 'SMART_LOCKER_MERCHANT_ID',
        businessUnit:this.loyaltyService.currentProgramme?.businessUnit,
        loyaltyProgram:this.loyaltyService.currentProgramme?.code
      }).toPromise()
      this.userService.smartLockerMerchantId = getSmartLockerId
      return getSmartLockerId
    }
    return ''
  }
}
