import { Pipe, PipeTransform } from '@angular/core';
import { MerchantPipe } from './merchant/merchant.pipe';
import { Observable } from 'rxjs';

@Pipe({
  name: 'actualizationLocation',
})
export class ActualizationLocationPipe implements PipeTransform {
  public COLLECTION_METHODS = {
    COLLECT_AT_CONCIERGE: 'COLLECT_AT_CONCIERGE',
    COLLECT_AT_PARTNER: 'COLLECT_AT_PARTNER',
    COLLECT_AT_TENANT: 'COLLECT_AT_TENANT',
    COLLECT_AT_TENANT_APP: 'COLLECT_AT_TENANT_APP',
    COLLECT_AT_CARPARK: 'COLLECT_AT_CARPARK',
    COLLECT_AT_VENDING_MACHINE: 'COLLECT_AT_VENDING_MACHINE',
    ACTUALIZE_AT_LOCKER_PANEL: 'ACTUALIZE_AT_LOCKER_PANEL',
  };

  locationMapping = {
    [this.COLLECTION_METHODS.COLLECT_AT_CONCIERGE]: 'CSO Counter',
  };
  constructor(public merchantPipe: MerchantPipe) {}
  transform(value: any, collectionMethod: string): Observable<string> {
    if (collectionMethod === this.COLLECTION_METHODS.COLLECT_AT_CONCIERGE) {
      return new Observable<string>((subscriber) => {
        const actualizationLocation = this.locationMapping[collectionMethod];

        subscriber.next(actualizationLocation);
        subscriber.complete();
      });
    } else if (
      collectionMethod === this.COLLECTION_METHODS.COLLECT_AT_PARTNER ||
      collectionMethod === this.COLLECTION_METHODS.COLLECT_AT_TENANT ||
      collectionMethod === this.COLLECTION_METHODS.COLLECT_AT_CARPARK ||
      collectionMethod === this.COLLECTION_METHODS.COLLECT_AT_TENANT_APP
    ) {
      return this.merchantPipe.transform(value);
    } else if (collectionMethod == this.COLLECTION_METHODS.COLLECT_AT_VENDING_MACHINE) {
      return new Observable<string>((subscriber) => {
        subscriber.next('Vending Machine');
        subscriber.complete();
      });
    } else if (collectionMethod == this.COLLECTION_METHODS.ACTUALIZE_AT_LOCKER_PANEL) {
      return new Observable<string>((subscriber) => {
        subscriber.next('Locker Panel');
        subscriber.complete();
      });
    } else {
      throw new Error('Unknown collection method.');
    }
  }
}
