import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '.form-stick-bottom-bar',
})
export class MainContainerClassDirective implements AfterViewInit, OnDestroy {
  private nativeElement: HTMLDivElement;

  constructor(private el: ElementRef) {
    this.nativeElement = this.el.nativeElement;
  }
  ngAfterViewInit() {
    if (this.nativeElement.style.display !== 'none') {
      this.nativeElement?.offsetParent?.querySelector('.admin-shell-content, .config-shell-content')?.classList.add('has-footer');
    } else {
      this.nativeElement?.offsetParent?.querySelector('.admin-shell-content, .config-shell-content')?.classList.remove('has-footer');
    }
  }

  ngOnDestroy() {
    this.nativeElement?.offsetParent?.querySelector('.admin-shell-content, .config-shell-content')?.classList.remove('has-footer');
  }
}
