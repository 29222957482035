import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { DropdownService } from '../../../../@core/services/application/generic-category/dropdown.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReimbursementService } from '../../../../@core/services/reimbursement/reimbursement.service';

@Component({
  selector: 'app-reimbursement-void-dialog',
  templateUrl: './reimbursement-void-dialog.component.html',
  styleUrls: ['./reimbursement-void-dialog.component.scss'],
})
export class VoidReimbursementDialogComponent implements OnInit {
  revertForm: UntypedFormGroup;
  afterSave: EventEmitter<any> = new EventEmitter<any>();
  voidData:any[] = [];
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dropdownService: DropdownService,
    public dialogRef: MatDialogRef<VoidReimbursementDialogComponent>,
    private fb: UntypedFormBuilder,
    public reimbursementService: ReimbursementService
  ) {
    this.revertForm = this.fb.group({
      reasons: [null, [Validators.required]],
      remark: [null],
    });
    this.voidData = this.data.dropdownData['REIMBURSEMENT']['REIMBURSEMENT_VOID_REASON']
  }
  ngOnInit() {
    this.revertForm.get('reasons')?.valueChanges.subscribe((res:any) => {
      if(res.includes("OTHERS")) {
        this.revertForm.get('remark')?.setValidators(Validators.required);
        this.revertForm.get('remark')?.updateValueAndValidity()
      }else {
        this.revertForm.get('remark')?.clearValidators();
        this.revertForm.get('remark')?.updateValueAndValidity()
      }
    })
  }
  onCancelClick() {
    this.dialogRef.close();
  }
  onClickSubmit() {
    this.revertForm.markAllAsTouched();
    this.revertForm.updateValueAndValidity();
    if (this.revertForm.valid) {
      this.transactionsVoid()
    }
  }
  transactionsVoid() {
    const getFormData = this.revertForm.getRawValue();
    this.isLoading = true;
     this.reimbursementService.transactionsVoid({
      remark: getFormData.remark,
      reasons: getFormData.reasons,
      merchantTransactionId: this.data.transactionId,
      guestId: this.data.guestId
     }).subscribe((res:any) => {
       this.isLoading = false;
        this.afterSave.emit(true);
        this.dialogRef.close();
     },() => {
      this.isLoading = false;
     })
  }
  
}
