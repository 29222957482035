import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '@app/@core';
import { ReimbursementService } from '@app/@core/services/reimbursement/reimbursement.service';
import { id } from 'date-fns/locale';

@Component({
  selector: 'app-reimbursement-history-dialog',
  templateUrl: './reimbursement-history-dialog.component.html',
  styleUrls: ['./reimbursement-history-dialog.component.scss'],
})
export class ReimbursementHistoryDialogComponent implements OnInit {
  isLoading: boolean = false;
  dataSource: any;
  summaryId: string;
  actionType: string;
  dropdownData: any = [];
  dropdownDataObj: any = {};
  constructor(private dialogService: DialogService, public dialogRef: MatDialogRef<ReimbursementHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public reimbursementService: ReimbursementService) {
    this.summaryId = data.summaryId;
    this.actionType = data.actionType;
    this.dropdownData = data.dropdownData;
    data.dropdownData.forEach((item: any) => {
      this.dropdownDataObj[item.value] = item.label;
    });
  }

  ngOnInit(): void {
    if (this.actionType == 'REIMBURSEMENT') this.getReimbursementLog();
    else this.getReimbursementSummaryLog();
  }

  getReimbursementSummaryLog() {
    this.reimbursementService.getHistory({ reimbursementSummaryId: this.summaryId }).subscribe((data) => {
      this.dataSource = data;
    });
  }
  getReimbursementLog() {
    this.reimbursementService.getDetailLog({ transactionId: this.summaryId }).subscribe((data: any) => {
      data = data.map((item: any) => {
        if (item.action === 'REIMBURSEMENT_REVERTED' && item.remark) {
          const regex = /\(([^)]+)\)/;
          const match = item.remark.match(regex);
          if (match) {
            const extractedValues = match[1].split(',');
            const extractedValuesReplace = extractedValues.join(',');
            let extractedValuesStr = '';
            extractedValues.map((key: string) => {
              if (this.dropdownDataObj[key]) {
                if (extractedValuesStr) {
                  extractedValuesStr += ',';
                }
                extractedValuesStr += this.dropdownDataObj[key];
              }
            });
            if (extractedValuesStr) {
              item.remark = item.remark.replace(new RegExp(extractedValuesReplace, 'g'), extractedValuesStr);
            }
          }
        }
        return item;
      });
      this.dataSource = data;
    });
  }
}
