<div class="row uploader-container">
  <div class="col-auto col-uploader">
    <div class="image-uploader-wrapper">
      <!-- Drag and Drop Zone-->
      <div
        #uploaderWrapper
        class="border-wrapper file-uploader-container"
        (click)="onClickUploaderWrapper($event)"
        (drop)="uploaderWrapperDrop($event, uploaderWrapper)"
        (dragover)="uploaderWrapperDragOver($event, uploaderWrapper)"
        (dragleave)="uploaderWrapperDragLeave($event, uploaderWrapper)"
        (dragend)="uploaderWrapperDragEnd($event, uploaderWrapper)"
      >
        <div class="row no-gutters icon-row">
          <div>
            <mat-icon class="uploader-icon">collections</mat-icon>
          </div>
        </div>
        <div class="row no-gutters">
          <span>Upload</span>
        </div>
        <!-- Hidden Uploader for tablet device     -->
        <input #fileInputRef style="display: none" type="file" accept="image/*" [attr.capture]="isEnableAlbumPicker ? null : 'environment'" (change)="onSelectFile($event)" />
      </div>
    </div>
  </div>
</div>
