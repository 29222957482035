<div class="reference-transaction-viewer">
  <div class="row">
    <div class="col-6">
      <div class="reference-transaction">
        <div class="section-header">
          <div class="row">
            <div class="col-sm">
              <span class="subsection-title"> Transaction {{ referencedTransactionDetail?.transactionDetail.id }} </span>
            </div>
            <div class="col-sm" style="text-align: right">
              <span class="subsection-status label"> {{ 'RECEIPT_TRANSACTIONS.LABEL.APPROVAL_STATUS' | translate }} : </span>
              <span class="subsection-status" translate [transactionStatus]="referencedTransactionDetail?.transactionDetail.status"></span>
            </div>
          </div>
        </div>
        <div class="member-info-section">
          <!-- * Member info section -->
          <transaction-guest-info [guestData]="referencedTransactionDetail.memberProfile"></transaction-guest-info>
        </div>
        <div class="secondary-image-viewer">
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.TENANT_RECEIPT</div>
            <div class="d-flex">
              <receipt-image-thumbnail style="flex: 1;" [isRpt]="isRpt" [images]="referencedTransactionDetail?.transactionDetail.salesReceipts"></receipt-image-thumbnail>
              <div class="big-status" *ngIf="['REJECTED','VOIDED'].includes(referencedTransactionDetail?.transactionDetail.status)">{{ referencedTransactionDetail?.transactionDetail.status | transactionStatusPipe }}</div>
            </div>
          </div>
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_RECEIPT</div>
            <receipt-image-thumbnail [isRpt]="isRpt&&!referencedTransactionDetail?.transactionDetail.salesReceipts?.length" [images]="referencedTransactionDetail?.transactionDetail.electronicReceipts"></receipt-image-thumbnail>
          </div>
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.MEMBER_IDENTITY</div>
            <receipt-image-thumbnail [images]="referencedTransactionDetail?.transactionDetail.memberIdentity"></receipt-image-thumbnail>
          </div>
        </div>
        <div class="transaction-info-section">
          <span class="subsection-title" translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_INFO</span>
          <div style="padding-top: 24px">
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TENANT</mat-label>
              <input matInput disabled [value]="referencedTransactionDetail.transactionDetail?.tenantId | merchant | async" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE</mat-label>
              <input matInput autocomplete="off" [value]="referencedTransactionDetail.transactionDetail?.transactionDate | systemDateFormat" readonly disabled />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_TIME</mat-label>
              <input matInput autocomplete="off" [value]="referencedTransactionTime" readonly disabled />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT</mat-label>
              <input matInput disabled [value]="referencedTransactionDetail.transactionDetail?.transactionAmount | systemCurrency" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT</mat-label>
              <input matInput disabled [value]="referencedTransactionDetail.transactionDetail?.receiptAmount | systemCurrency" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD</mat-label>
              <input matInput disabled [value]="referencedTransactionDetail.transactionDetail?.paymentMethod | genericCategory : 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async" />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.EXCLUDED_AMOUNT_FROM_PAYMENT</mat-label>
              <input matInput disabled [value]="isRpt?(referencedTransactionDetail.transactionDetail?.rptExcludedAmount | systemCurrency) : (referencedTransactionDetail.transactionDetail?.rptExcludedAmount | replaceEmptyValue)" />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ACTUALIZAED_EVOUCHER</mat-label>
              <input matInput disabled [value]="isRpt?(referencedTransactionDetail.transactionDetail?.actualizedTransactionAmount | systemCurrency):(referencedTransactionDetail.transactionDetail?.actualizedTransactionAmount | replaceEmptyValue)" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER</mat-label>
              <input matInput disabled [value]="isRpt?(referencedTransactionDetail.transactionDetail?.invoiceNumbers?.join(',') | replaceEmptyValue):referencedTransactionDetail.transactionDetail?.invoiceNumbers" />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.RPT_ACQUIRE_REF_NO</mat-label>
              <input matInput disabled [value]="isRpt?(referencedTransactionDetail.transactionDetail?.rptAcquireReferenceNos | joinBySeparator : 'acquireReferenceNo') : ((referencedTransactionDetail.transactionDetail?.rptAcquireReferenceNos | joinBySeparator : 'acquireReferenceNo') | replaceEmptyValue)" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_REMARKS</mat-label>
              <input matInput disabled [value]="referencedTransactionDetail.transactionDetail?.remarks || ''" />
            </mat-form-field>
            <span class="ocr-remark compareVoidReceipt">
              Uploaded by {{referencedTransactionDetail.transactionDetail?.rptReceipt ? (referencedTransactionDetail.transactionDetail?.tenantId | merchant | async) : referencedTransactionDetail.transactionDetail?.createdBy }} on {{ referencedTransactionDetail.transactionDetail?.createdDate | systemDateTimeFormat }}
              <div>
                <c-form-button
                  *ngIf="TransactionConstants.voidableTransactionStatuses.includes(referencedTransactionDetail?.transactionDetail.status) && !TransactionConstants.notVoidTransactionType.includes(referencedTransactionDetail?.transactionDetail.type)"
                  [appCanAccess]="'ui#loyalty#transaction#voidButton'"
                  label="Void Receipt"
                  [upperCaseLabel]="true"
                  (click)="onClickVoidReceipt()"
                >
                </c-form-button>
                <c-form-button
                  *ngIf="TransactionConstants.rejectableTransactionStatuses.includes(referencedTransactionDetail?.transactionDetail.status)"
                  label="Reject"
                  [upperCaseLabel]="true"
                  (click)="onClickRejectButton()"
                >
                </c-form-button>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="current-transaction">
        <div class="section-header">
          <div class="row">
            <div class="col-sm">
              <span class="subsection-title">Transaction {{ originalTransactionData?.transactionDetail?.id }}</span>
            </div>
            <div class="col-sm" style="text-align: right" *ngIf="originalTransactionData?.transactionDetail?.status">
              <span class="subsection-status label" translate>RECEIPT_TRANSACTIONS.LABEL.APPROVAL_STATUS</span> :
              <span class="subsection-status" translate [transactionStatus]="originalTransactionData?.transactionDetail?.status"></span>
            </div>
          </div>
        </div>
        <div class="member-info-section">
          <transaction-guest-info [guestData]="originalTransactionData?.memberProfile"></transaction-guest-info>
        </div>
        <div class="secondary-image-viewer">
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.TENANT_RECEIPT</div>
            <receipt-image-thumbnail [isRpt]="isCompareRpt" [isLocalImage]="isFromLocalTransaction" [images]="originalTransactionData?.transactionDetail?.salesReceipts"></receipt-image-thumbnail>
          </div>
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_RECEIPT</div>
            <receipt-image-thumbnail [isRpt]="isCompareRpt&&!originalTransactionData?.transactionDetail?.salesReceipts?.length" [isLocalImage]="isFromLocalTransaction" [images]="originalTransactionData?.transactionDetail?.electronicReceipts"></receipt-image-thumbnail>
          </div>
          <div>
            <div class="thumbnail-type-title" translate>RECEIPT_TRANSACTIONS.LABEL.MEMBER_IDENTITY</div>
            <receipt-image-thumbnail [isLocalImage]="isFromLocalTransaction" [images]="originalTransactionData?.transactionDetail?.memberIdentity"></receipt-image-thumbnail>
          </div>
        </div>
        <div class="transaction-info-section">
          <span class="subsection-title" translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_INFO</span>
          <div style="padding-top: 24px">
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TENANT</mat-label>
              <input matInput disabled [value]="originalTransactionData?.transactionDetail?.tenantId | merchant | async" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_DATE</mat-label>
              <input matInput autocomplete="off" [value]="originalTransactionData?.transactionDetail?.transactionDate | systemDateFormat" readonly disabled />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_TIME</mat-label>
              <input matInput autocomplete="off" [value]="originTransactionTime" readonly disabled />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_AMOUNT</mat-label>
              <input matInput disabled [value]="originalTransactionData?.transactionDetail?.transactionAmount | systemCurrency" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ELEC_AMOUNT</mat-label>
              <input matInput disabled [value]="originalTransactionData?.transactionDetail?.receiptAmount | systemCurrency" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD</mat-label>
              <input matInput disabled [value]="originalTransactionData?.transactionDetail?.paymentMethod | genericCategory : 'RECEIPT_TRANSACTION.PAYMENT_METHOD' | async" />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.EXCLUDED_AMOUNT_FROM_PAYMENT</mat-label>
              <input matInput disabled [value]="isCompareRpt?(originalTransactionData.transactionDetail?.rptExcludedAmount | systemCurrency) : (originalTransactionData.transactionDetail?.rptExcludedAmount | replaceEmptyValue)" />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.ACTUALIZAED_EVOUCHER</mat-label>
              <input matInput disabled [value]="isCompareRpt?(originalTransactionData.transactionDetail?.actualizedTransactionAmount | systemCurrency): (originalTransactionData.transactionDetail?.actualizedTransactionAmount | replaceEmptyValue)" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER</mat-label>
              <input matInput disabled [value]="isCompareRpt?(originalTransactionData?.transactionDetail?.invoiceNumbers| replaceEmptyValue) : originalTransactionData?.transactionDetail?.invoiceNumbers" />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="featureFlagService.getFeatureFlag('RPT')">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.RPT_ACQUIRE_REF_NO</mat-label>
              <input matInput disabled [value]="isCompareRpt?originalTransactionData.transactionDetail?.rptAcquireReferenceNos:(originalTransactionData.transactionDetail?.rptAcquireReferenceNos.join(',') | replaceEmptyValue)" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label translate>RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_REMARKS</mat-label>
              <input matInput disabled [value]="originalTransactionData?.transactionDetail?.remarks || ''" />
            </mat-form-field>
            <span *ngIf="originalTransactionData?.transactionDetail?.createdBy" class="ocr-remark compareVoidReceipt">
              Uploaded by {{ originalTransactionData?.transactionDetail?.createdBy }} on {{ originalTransactionData?.transactionDetail?.createdDate | systemDateTimeFormat }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
